
import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import ChangeMobileMixin from '@/mixins/changeMobile'
import { mobileRegExp } from '@/utils/reg'
import InputItem from '@/components/InputItem.vue'
import { inBenlaiApp, dsBridge, compareVersion, isWechat } from '@/utils/env'
import getCurrentQuery from '@/utils/querystrings'
import ModifyPhoneVerificationCode from '@/components/bindphone/VerificationCode.vue'
@Component({
  name: 'verify',
  components: {
    'input-item': InputItem,
    'verification-code': ModifyPhoneVerificationCode
  },
  mixins: [ChangeMobileMixin]
})
export default class VerifyPage extends Vue {
  @Provide() public page: number = 1 // 1,验证当前手机页面 2,填写要修改手机号页面  3，获取验证码并提交修改
  @Provide() public p: string = '' // 当前安全手机号带***
  @Provide() public code: string = '' // 验证码
  @Provide() public codeArr: any = [] // 验证码数组
  @Provide() public sms: string = '' // 验证码
  @Provide() public smsArr: any = [] // 验证码数组
  @Provide() public errorMsg: string = '' // 错误提示
  @Provide() public mobile: string = '' // 手机号
  @Provide() public apiError: string = '' // 调用错误提示
  @Provide() public token: string = '' // 验证后接口给的token
  @Provide() public isShowClent: boolean = true // 在线客服入口是否显示
  @Provide() public isFromH5: boolean = false // 在线客服入口是否显示
  @Provide() public isFromLife: boolean = false // 是否来自life.benlai.com
  @Provide() public isFromPublic: boolean = false // 是否来自其他小程序
  @Provide() public isInWechat: boolean = isWechat // 是否在微信域内
  public created () {
    const version = getCurrentQuery('version') || ''
    const from = getCurrentQuery('from') || ''
    const otc = getCurrentQuery('otc') || ''
    this.isFromH5 = from === 'h5'
    this.isFromLife = from === 'life'
    this.isFromPublic = from === 'public'
    this.isShowClent = version ? compareVersion(version, '8.6.9') : true
    this.refresh()
  }
  @Emit()
  public async refresh () {
    const [err, res] = await this.getMobile()
    if (res.data) {
      this.p = res.data
    }
  }
  @Emit()
  public async sendCode () {
    const [err, res] = await this.sendV5SMSCode('', 4)
    if (err && err.error && err.message) {
      if (this.page === 1) { this.errorMsg = err.message } else { this.apiError = err.message }
    } else {
      this.$toasted.show('验证码获取成功！')
      this.errorMsg = ''
    }
  }
  @Emit()
  public async next () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    this.page = 3
    this.smsArr = []
    this.sms = ''
  }
  @Emit()
  public setValue (detail: any) {
    this.mobile = detail.value
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }
  @Emit()
  public async verify () {
    this.codeArr = String(this.code).split('') || []
    if (this.codeArr.length === 6) {
      const [err, res] = await this.verifyMobile(String(this.code))
      if (err && err.error && err.message) {
        this.errorMsg = err.message
      } else {
        this.token = res.data.token
        await this.clearCountDown()
        this.page = 2
        this.codeArr = []
        this.code = ''
      }
    }
  }
  // @Emit()
  // public async login () {
  //   this.smsArr = String(this.sms).split('') || []
  //   if (this.smsArr.length === 6) {
  //     const [err, res] = await this.modifyMobile(this.mobile, String(this.sms), this.token, true)
  //     if (err && err.error && err.message) {
  //       this.apiError = err.message
  //     } else {
  //       this.$toasted.show('修改成功！')
  //       const self = this
  //       setTimeout(() => {
  //         if (self.isFromH5) {
  //           location.replace(`https://h5.benlai.com/me`)
  //           return
  //         }
  //         if (self.isFromLife) {
  //           // location.replace(`https://life.benlai.com/me`)
  //           const wx = (window as any).wx
  //           wx.miniProgram.navigateBack({
  //             delta: 1
  //           })
  //           this.$router.go(-1)
  //           return
  //         }
  //         if (self.isFromPublic) {
  //           const wx = (window as any).wx
  //           wx.miniProgram.navigateBack({
  //             delta: 1
  //           })
  //           return
  //         }
  //         if (document.referrer.includes('m.benlai.com')) {
  //           location.replace(`https://m.benlai.com/userHome/securityCenter${location.search}`)
  //         } else {
  //           location.replace(`https://mc.benlai.com/safeCenter/SafeCenter`)
  //         }
  //       }, 2500)
  //     }
  //   }
  // }
  @Emit()
  public goSobot () {
    if (inBenlaiApp) {
      dsBridge.call('toCustomerServiceList', '', () => {
        console.log('进入dsBridge')
      })
    } else {
      location.href = `https://mc.benlai.com/user/customerService`
    }
  }
  @Emit()
  public async goBack () {
    if (this.page > 1) {
      this.page = this.page - 1
      await this.clearCountDown()
    } else {
      window.history.back()
    }
  }
}
